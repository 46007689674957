import { LOADER_SHOW, LOADER_HIDE, LOADER_TOGGLE } from '../actions/types'

const initialState = {
  loaderShow: false,
}

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER_SHOW:
      return {
        ...state,
        loaderShow: true,
      }

    case LOADER_HIDE:
      return {
        ...state,
        loaderShow: false,
      }
    case LOADER_TOGGLE:
      return {
        ...state,
        loaderShow: state.loaderShow ? false : true,
      }
    default: {
      return state
    }
  }
}

export default loaderReducer
