import {
  SET_COMMON_DATA,
  SET_COMMON_STATUS,
  SET_COMMON_DEVICE_TYPE_DATA,
  SET_COMMON_CUSTOMER_TAG,
} from '../actions/types'

const initialState = {
  commonData: {},
  commonAttributes: [],
  defaultPermissions: [],
  inventoryProductStatus: {},
  confirmRepairStatus: {},
  repairStatus: {},
  inventoryListStatus: {},
  reconciliationStatus: {},
  commonDeviceType: [],
  orderStatus: {},
  orderBuyProductStatus: {},
  orderBuyStatus: {},
  whatIsInTheBox: [],
  collections: [],
  customerTags: [],
  possibleEmailParams: [],
  moduleTypes: {},
  userActions: {},
  PHONECHECK_STATUS_RESULT: {},
}

const appCommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_DATA:
      return {
        ...state,
        commonData: action.payload.data,
        defaultPermissions: action.payload.super_admin_permission,
        commonAttributes: action.payload.attributes,
        whatIsInTheBox: action.payload?.whatIsInTheBox[0]?.value,
        collections: action.payload?.collections[0]?.value,
      }
    case SET_COMMON_DEVICE_TYPE_DATA:
      return {
        ...state,
        commonDeviceType: action.payload,
      }
    case SET_COMMON_STATUS:
      return {
        ...state,
        inventoryProductStatus: action.payload.formatedProductStatus,
        confirmRepairStatus: action.payload.followResponse,
        //repairStatus: action.payload.repairTypes,
        inventoryListStatus: action.payload.inventoryStatus,
        reconciliationStatus: action.payload.reconciliationStatus,
        repairStatus: action.payload.repairStatus,
        inventoryPeningProdcutStatus: action.payload.emailTypes,
        orderStatus: action.payload.orderStatus,
        orderBuyProductStatus: action.payload.orderBuyProductStatus,
        orderBuyStatus: action.payload.orderBuyStatus,
        orderStatusesForBuy: action.payload.orderStatusesForBuy,
        trackShippingStatusForShopOrder: action.payload.trackShippingStatus,
        customerTags: action.payload.customerTags.body,
        PHONECHECK_STATUS_RESULT: action.payload.PHONECHECK_STATUS_RESULT,
        possibleEmailParams: action.payload.possibleEmailParams,
        moduleTypes: action.payload.moduleTypes,
        userActions: action.payload.userActions,
      }
    case SET_COMMON_CUSTOMER_TAG:
      return {
        ...state,
        customerTags: action.payload.customerTags.body,
      }
    default: {
      return state
    }
  }
}

export default appCommonReducer
