import React from 'react'
import { CModal, CModalBody, CModalTitle, CModalHeader } from '@coreui/react'
import { HIDE_GALLERY_MODAL } from 'src/redux/actions/types'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const GalleryModal = ({ visible, heading = 'Image Gallery', galleryData }) => {
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch({ type: HIDE_GALLERY_MODAL })
  }

  return (
    <CModal
      backdrop="static"
      visible={visible}
      size="sm"
      alignment="center"
      className="confirmation-modal"
      onClose={() => onClose()}
    >
      <CModalHeader className="border-none">
        <CModalTitle>{heading}</CModalTitle>
      </CModalHeader>
      <CModalBody className="center-v-h flex-direction-column">
        <ul className="gallery-images mt-4">
          {galleryData.map((image, k) => {
            return (
              <li key={k}>
                <img src={image.imageUrl} alt="gimg" />
              </li>
            )
          })}
        </ul>
      </CModalBody>
    </CModal>
  )
}

GalleryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  galleryData: PropTypes.array,
}

export default GalleryModal
