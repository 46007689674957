import React, { Suspense, useState, useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CButton, CToastClose, CToaster, CSpinner, CToast, CToastBody } from '@coreui/react'
import { useSelector } from 'react-redux'
import Protected from './Protected'
import { Navigate } from 'react-router-dom'
import Confirm from './components/modals/Confirm'
import CommonData from './GetCommonData'
import Socket from './services/socket/Socket'
import Gallery from './components/modals/Gallery'
import LeavePageWarningModal from './components/modals/LeavePageWarningModal'
import './scss/style.scss'

const Loading = (
  <div className="bg-light min-vh-100 d-flex flex-column align-items-center">
    <div className={`loader-container`}>
      <CSpinner />
    </div>
  </div>
)

const CommonModals = () => {
  const {
    confirmationModal,
    heading,
    description,
    galleryModal,
    galleryData,
    leavePageWarning,
    leavePageData,
    btnTitle,
  } = useSelector((state) => state.modal)
  return (
    <>
      <Confirm
        visible={confirmationModal}
        btnTitle={btnTitle}
        heading={heading}
        description={description}
      />
      <Gallery visible={galleryModal} galleryData={galleryData} />
      <LeavePageWarningModal visible={leavePageWarning} data={leavePageData} />
    </>
  )
}

const Toasts = () => {
  const toastData = useSelector((state) => state.toast)
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  useEffect(() => {
    const exampleToast = (
      <CToast
        autohide={true}
        color={toastData.settings.success ? 'success' : 'danger'}
        className="text-white align-items-center"
      >
        <div className="d-flex t-container">
          <img
            src={
              toastData.settings.success
                ? '/assets/icons/toast-tick.svg'
                : '/assets/icons/toast-error.svg'
            }
            alt="tick"
          />
          <div>
            <CToastBody className="toast-head">
              {toastData.settings.success ? 'Success' : 'Error'}
            </CToastBody>
            <CToastBody className="toast-message" style={{ lineHeight: '20px' }}>
              {toastData.settings.message}
            </CToastBody>
          </div>
          <CToastClose component={CButton} color="secondary" size="sm" className="ms-1">
            <img src="/assets/icons/toast-close.svg" alt="tick" />
          </CToastClose>
        </div>
      </CToast>
    )
    if (toastData.show) {
      addToast(exampleToast)
    }
  }, [toastData])
  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

const Loader = (show) => {
  const loader = useSelector((state) => state.loader.loaderShow)
  return (
    <div className={`loader-container ${loader === true || show === true ? '' : 'd-none'}`}>
      <CSpinner />
    </div>
  )
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/auth/Login'))
const UploadImage = React.lazy(() => import('./views/pages/UploadImage'))

const Forgot = React.lazy(() => import('./views/pages/auth/Forgot'))
const Reset = React.lazy(() => import('./views/pages/auth/ResetPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated)

  const [loadComponent, setLoadComponent] = useState(false)
  return (
    <BrowserRouter>
      {isLoggedIn && <CommonData setLoadComponent={setLoadComponent} />}
      <Suspense fallback={Loading}>
        <Loader />
        <Toasts />
        <Socket />
        <CommonModals />
        <Routes>
          <Route
            exact
            path="/"
            name="Login Page"
            element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />}
          />
          <Route exact path="/upload-image" name="Upload Image" element={<UploadImage />} />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Page"
            element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Forgot />}
          />
          <Route exact path="/reset-password" name="Reset Password" element={<Reset />} />
          <Route exact path="/create-password" name="Create Password" element={<Reset />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <DefaultLayout loadComponent={loadComponent} />
              </Protected>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
