import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFICATION_SUCCESS,
  LOGOUT,
  UPDATE_PROFILE,
  SOCKET_CALLBACK,
} from '../actions/types'

const initialState = {
  user: localStorage.profile ? JSON.parse(localStorage.profile) : '',
  isAuthenticated: localStorage.profile ? true : false,
  socketCallback: {},
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('profile', JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      }

    case UPDATE_PROFILE:
      localStorage.setItem('profile', JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case LOGOUT:
      localStorage.clear()
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case VERIFICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      }
    case SOCKET_CALLBACK:
      return {
        ...state,
        socketCallback: action.payload,
      }

    default: {
      return state
    }
  }
}

export default authReducer
