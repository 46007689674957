import React from 'react'
import { CModal, CModalBody, CButton, CModalHeader } from '@coreui/react'
import { HIDE_CONFIRMATION_MODAL, SUBMIT_CONFIRMATION } from 'src/redux/actions/types'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const Confirm = ({
  visible,
  heading = 'Are You Sure?',
  description = 'Are you sure you want to delete this?',
  btnTitle = '',
  info,
  callback,
  type,
}) => {
  const dispatch = useDispatch()

  const onAccept = () => {
    if (type === 'non-redux') {
      callback({ type: 'accept', data: info })
    }
    dispatch({ type: SUBMIT_CONFIRMATION })
  }

  const onClose = () => {
    if (type === 'non-redux') {
      callback({ type: 'close', data: info })
    }
    dispatch({ type: HIDE_CONFIRMATION_MODAL })
  }

  return (
    <CModal
      backdrop="static"
      visible={visible}
      size="sm"
      alignment="center"
      className="confirmation-modal"
      onClose={() => onClose()}
    >
      <CModalHeader className="border-none"></CModalHeader>
      <CModalBody className="center-v-h flex-direction-column">
        <img className="confirmation-icon" src="/assets/icons/cross.svg" alt="cross" />
        <h4 className="confirmation-text">{heading}</h4>
        <p className="confirmation-desc">{description}</p>
        <div className="confirmation-buttons">
          <CButton className="btn-gray" onClick={onClose}>
            Close
          </CButton>
          <CButton className="btn-orange btn-white-color" onClick={onAccept}>
            {btnTitle ? btnTitle : 'Delete'}
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  )
}

Confirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
  info: PropTypes.any,
  callback: PropTypes.func,
  type: PropTypes.string,
  btnTitle: PropTypes.string,
}

export default Confirm
