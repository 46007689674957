import {
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  SUBMIT_CONFIRMATION,
  SHOW_GALLERY_MODAL,
  HIDE_GALLERY_MODAL,
  CSV_IMPORT_OPEN_MODAL,
  CSV_IMPORT_CLOSE_MODAL,
  CSV_IMPORT_SET_FILES,
  CSV_IMPORT_SUBMIT,
  SHOW_LEAVE_WARNING_MODAL,
  HIDE_LEAVE_WARNING_MODAL,
} from '../actions/types'

const initialState = {
  confirmationModal: false,
  submitConfirmation: false,
  heading: '',
  description: '',
  galleryData: [],
  galleryModal: false,
  csvImportModal: false,
  submitCSVImport: false,
  csvImportFiles: [],
  leavePageWarning: false,
  leavePagePath: '',
  leavePageData: { heading: 'You have unsaved changes', description: 'Do you want to go back ?' },
  leavePageSetting: { redirect: false },
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: true,
        submitConfirmation: false,
        ...action.payload,
      }
    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: false,
        submitConfirmation: false,
      }
    case SHOW_LEAVE_WARNING_MODAL:
      return {
        ...state,
        leavePageWarning: true,
        ...action.payload,
      }
    case HIDE_LEAVE_WARNING_MODAL:
      return {
        ...state,
        leavePageWarning: false,
        ...action.payload,
      }
    case SUBMIT_CONFIRMATION:
      return {
        ...state,
        submitConfirmation: true,
      }
    case SHOW_GALLERY_MODAL:
      return {
        ...state,
        galleryModal: true,
        ...action.payload,
      }
    case HIDE_GALLERY_MODAL:
      return {
        ...state,
        galleryModal: false,
        ...action.payload,
      }
    case CSV_IMPORT_OPEN_MODAL:
      return {
        ...state,
        csvImportModal: true,
      }
    case CSV_IMPORT_CLOSE_MODAL:
      return {
        ...state,
        csvImportModal: false,
        submitCSVImport: false,
        csvImportFiles: [],
      }
    case CSV_IMPORT_SET_FILES:
      return {
        ...state,
        csvImportFiles: action.payload,
      }
    case CSV_IMPORT_SUBMIT:
      return {
        ...state,
        submitCSVImport: true,
      }
    default: {
      return state
    }
  }
}

export default modalReducer
