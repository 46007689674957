import { VALIDATE_ADD_ORDER } from '../actions/types'

const initialState = {
  validateDeviceInfo: false,
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_ADD_ORDER:
      return {
        ...state,
        validateDeviceInfo: action.payload.validate,
      }
    default: {
      return state
    }
  }
}

export default orderReducer
