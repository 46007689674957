import { TOAST_SHOW, TOAST_HIDE } from '../actions/types'

const initialState = {
  show: false,
  settings: {},
}

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_SHOW:
      return {
        ...state,
        show: true,
        settings: action.payload,
      }
    case TOAST_HIDE:
      return {
        ...state,
        show: false,
        settings: {},
      }
    default: {
      return state
    }
  }
}

export default toastReducer
