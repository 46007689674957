import { createStore, combineReducers } from 'redux'
import authReducers from './reducers/authReducer'
import sidebarReducer from './reducers/sidebarReducer'
import toastReducer from './reducers/toastReducer'
import loaderReducer from './reducers/loaderReducer'
import modalReducer from './reducers/modalReducer'
import notificationReducer from './reducers/notificationReducer'
import appCommonReducer from './reducers/appCommonReducer'
import orderReducer from './reducers/orderReducer'

const rootReducers = combineReducers({
  auth: authReducers,
  sidebar: sidebarReducer,
  toast: toastReducer,
  loader: loaderReducer,
  modal: modalReducer,
  webNotification: notificationReducer,
  appCommon: appCommonReducer,
  order: orderReducer,
})

const store = createStore(rootReducers)

export default store
