import React from 'react'
import { CModal, CModalBody, CButton, CModalHeader } from '@coreui/react'
import PropTypes from 'prop-types'
import { cilWarning } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useDispatch } from 'react-redux'
import { HIDE_LEAVE_WARNING_MODAL } from 'src/redux/actions/types'

const LeavePageWarningModal = ({ visible, data: { heading, description } }) => {
  const dispatch = useDispatch()

  return (
    <CModal
      backdrop="static"
      visible={visible}
      size="sm"
      alignment="center"
      className="confirmation-modal"
      onClose={() =>
        dispatch({
          type: HIDE_LEAVE_WARNING_MODAL,
          payload: { leavePageSetting: { redirect: false } },
        })
      }
    >
      <CModalHeader className="border-none"></CModalHeader>
      <CModalBody className="center-v-h flex-direction-column">
        <CIcon
          className="confirmation-icon"
          icon={cilWarning}
          style={{ width: '50px', height: 'auto', color: '#f26d24' }}
        />
        <h4 className="confirmation-text">{heading}</h4>
        <p className="confirmation-desc" style={{ fontSize: '16px' }}>
          {description}
        </p>
        <div className="confirmation-buttons mt-3">
          <CButton
            className="btn-gray"
            onClick={() =>
              dispatch({
                type: HIDE_LEAVE_WARNING_MODAL,
                payload: { leavePageSetting: { redirect: true } },
              })
            }
          >
            Leave Page
          </CButton>
          <CButton
            className="btn-orange"
            onClick={() =>
              dispatch({
                type: HIDE_LEAVE_WARNING_MODAL,
                payload: { leavePageSetting: { redirect: false } },
              })
            }
          >
            Stay
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  )
}

LeavePageWarningModal.propTypes = {
  visible: PropTypes.any,
  data: PropTypes.any,
}

export default LeavePageWarningModal
