import { ORDER_COUNT, HANDLE_NOTIFICATION } from '../actions/types'

const initialState = {
  sidebar: {
    totalOrders: 0,
    totalSoldOrders: 0,
    totalBuyOrders: 0,
  },
  notifications: [],
  notificationCount: 0,
}

const notificationReducer = (state = initialState, action) => {
  let payload = action.payload
  switch (action.type) {
    case ORDER_COUNT:
      return {
        ...state,
        sidebar: {
          totalOrders: payload?.orderSellCount + payload?.orderBuyCount,
          totalSoldOrders: payload?.orderSellCount,
          totalBuyOrders: payload?.orderBuyCount,
        },
      }
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notifications: [...payload.data, ...state.notifications],
        notificationCount: payload?.data[0]?.count || payload?.count || 0,
      }
    default: {
      return state
    }
  }
}

export default notificationReducer
